
const state = {
    isLoading: false,
    SelectedSocieta: null
}

const getters =  {
    isLoading: (state) => {
        return state.isLoading;
    },
    getSelectedSociety: (state) =>{
        return state.SelectedSocieta;
    }
}

const mutations = {
    setLoader: (state, payload) => {
        state.isLoading = payload;
    },
    setSelectedSociety: (state, payload) =>{
        state.SelectedSocieta = payload;
    }
}

const actions = {
    setLoader: ({commit}, payload) => {
        commit('setLoader', payload.value);
    }
}

export default{
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}