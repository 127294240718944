import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
class ResourcesService{

    getRisorse(token, shop = "b2b"){     
        return axios({
            method: 'get',
            url: API_URL + shop +'/Risorse/GetRisorse', 
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {    
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }               
            }
        )
        .catch(error => {
            let ret = {
                Status: error.response.status,
                Data: error.response.data
            };
            return ret;
        });            
    }
}

export default new ResourcesService();