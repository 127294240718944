<template>
<div id="Loader" v-show="this.isLoading">
    <div class="Spinner">
        <div class="SpinnerContainer">
            <span class="SpinnerCircle"></span>
            <span class="SpinnerCircle"></span>
            <span class="SpinnerCircle"></span>
            <span class="SpinnerCircle"></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Loader",
    computed:{        
        isLoading: function(){      
            let il = this.$store.getters.isLoading;        
            return il;
        },
    },
}
</script>