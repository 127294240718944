import axios from 'axios'
import store from "@/store"

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {

  async registrazioneTesserato ( registrationData ){
    return axios({
        url: API_URL + 'b2c/Utenti',
        method: "POST",          
        headers:{
          'Content-Type': 'application/json'
        },     
        data: registrationData
    })
    .then(
      data =>  { 
        return data;
      }
    )
    .catch(error => {       
      return error.response;
    });
  }

    async login(username, password, shop) {
      
      let loginEnv = "";
      let EnvKey = "";
      if (shop == "b2c") {
        loginEnv = "ECM"
        EnvKey = process.env.VUE_APP_LOGIN_SECURE_B2C;
      } else {
        loginEnv = "B2B"
        EnvKey = process.env.VUE_APP_LOGIN_SECURE_B2B;
      }
      let b64 = Buffer.from(JSON.stringify({
        username: username,
        password: password,
        codShop: loginEnv
      })).toString("base64")
      return axios
        .post(API_URL + shop + '/Account/Login', JSON.stringify(b64) , { mode:"CORS", headers: {'Authorization': EnvKey, 'content-type': 'application/json'
      } })
        .then(response => {
          if (response.data.Data.token) {
              if (shop == "b2c") {
                let Utente = {
                  Username:   response.data.Data.Email,
                  Nome:       response.data.Data.Nome,
                  Cognome:    response.data.Data.Cognome,
                  Email:      response.data.Data.Email,
                  Telefono:   response.data.Data.Telefono,
                  Token:      response.data.Data.token,
                  Tesserati:  response.data.Data.Tesserati,
                  Shop:       shop,
                  Lingua:     "IT",
                }
                store.commit('setUtente',Utente);
              } else {
                let Utente = {
                  Username:   response.data.Data.Email,
                  Nome:       response.data.Data.Nome,
                  Cognome:    response.data.Data.Cognome,
                  Email:      response.data.Data.Email,
                  Token:      response.data.Data.token,
                  Shop:       shop,
                  Lingua:     "IT",
                }                  
                store.commit('setUtente',Utente);
                store.commit('setAzienda',response.data.Data.Azienda);
              }
          }
          return response.data.Data;
        });
    }

    async cambioPassword(token, campioPasswordData, shop){
      return axios({
          url: API_URL + shop  + '/account/CambioPassword',
          method: "post",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
          data: campioPasswordData
      })
      .then(
        data =>  {           
          return data;
        }
      )
      .catch(error => {            
        return error.response;
      });
    }

    async recuperoPassword(payload, shop){
      return axios({
          url: API_URL + shop + '/account/RecuperoPassword',
          method: "post",          
          headers:{         
            'Content-Type': 'application/json'
          },     
          data: payload
      })
      .then(
        data =>  {                 
          return data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async checkLogin(token, shop){
      return axios({
          url: API_URL + shop + '/Account/CheckSessione',
          method: "get",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },
      })
      .then(
        data =>  {                 
          if(data && data.status == 200){
            return true;
          }
          else{
            return false;
          }
        }
      )
      .catch(error => {       
        return false;
      });
    }

    async getUtentiB2C(token){
      return axios({
          url: API_URL + 'b2b/Utenti/UtentiB2c',
          method: "GET",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
      })
      .then(
        data =>  {
          return data.data.Data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async attivaUtenteB2C(token, utente){
      return axios({
          url: API_URL + 'b2b/Utenti/AbilitaTesserto?username=' + utente,
          method: "POST",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
      })
      .then(
        data =>  {
          return data.Data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async disattivaUtenteB2C(token, utente){
      return axios({
          url: API_URL + 'b2b/Utenti/DisabilitaTesserto?username=' + utente,
          method: "POST",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
      })
      .then(
        data =>  {
          return data.data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async getIndirizziSpedizione(token){
      return axios({
          url: API_URL + 'b2c/Utenti/IndirizziSpedizione',
          method: "GET",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
      })
      .then(
        data =>  {
          return data.data.Data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }

    async getIndirizziSpedizioneB2B(token){
      return axios({
          url: API_URL + 'b2b/Utenti/IndirizziSpedizione',
          method: "GET",          
          headers:{
            Authorization: 'Bearer '+ token,
            'Content-Type': 'application/json'
          },     
      })
      .then(
        data =>  {
          return data.data.Data;
        }
      )
      .catch(error => {       
        return error.response;
      });
    }



  }


  
  export default new AuthService();