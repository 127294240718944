
export default class CarrelloTestata{    
    Username;
    IdCarrello;
    PrezzoTotale;
    DataCreazione;
    DataModifica;
    Valuta;   
}

