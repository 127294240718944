import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class CategoriesService{

    getCategories(token, shop){
        return axios({
            method: 'get',
            url: API_URL + shop + '/Articoli/GetAlberoCategorie',          
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});            
    }

    getCategoryDefault(token, idCatalogo){
        return axios({
            method: 'get',
            url: API_URL + 'categorie/GetCategoriaDefaultByCatalogo', 
            params: {
                catalogo: idCatalogo
            },            
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});            
    }
}

export default new CategoriesService();