import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

class CartService{

    async setCart(token, shop, carrello){
        return axios({
            method: 'post',
            url: API_URL + shop +'/Carrello/SetCarrello',
            data: carrello,
            headers:{
                    Authorization: 'Bearer '+ token,
                    'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }              
            }
        )
        .catch(error => {return error;});
    }

    async updateRow(token, shop,  idCarrello, codiceArticolo, codiceVariante, quantita){
        return axios({
            method: 'put',
            url: API_URL + shop + '/carrello/UpdateRigaCarrello',
            data:{
                quantita: quantita,
            },           
            headers:{
                    Authorization: 'Bearer '+ token,
                    'Content-Type': 'application/json'
            },
            params:{
                idCarrello: idCarrello,
                codiceArticolo: codiceArticolo,
                codiceVariante: codiceVariante
            }
        })
        .then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }              
            }
        )
        .catch(error => {return error;});
    }

    async deleteRow(token, shop, idCarrello, idProdotto, idVariante){
        return axios({
            method: 'delete',
            url: API_URL + shop+ '/Carrello/DeleteRigaCarrello',                 
            headers:{
                    Authorization: 'Bearer '+ token,
                    'Content-Type': 'application/json'
            },
            params:{
                idCarrello: idCarrello,
                codiceArticolo: idProdotto,                
                codiceVariante: idVariante
            }
        })
        .then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }              
            }
        )
        .catch(error => {return error;});
    }

    async getCart(token, shop){
        return axios({
            method: 'get',
            url: API_URL + shop +'/Carrello/GetCarrello',                 
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        })
        .then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }              
            }
        )
        .catch(error => {return error;});
    }

    async getPaymentForm(metodo, shop, importo){
        return axios({
            method: 'get',
            url: API_URL + 'pagamenti/Digitali/GetPagamento',                 
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                metodoPagamento: metodo,
                codShop: shop,                
                importo: importo
            }
        })
        .then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data.Data;
                }
            }
        )
        .catch(error => {return error;});
    }
}

export default new CartService();