import { createRouter, createWebHashHistory } from 'vue-router'

const Login = () => import(/* webpackChunkName: "login-chunk" */ '@/views/Login.vue');
const Registration = () => import(/* webpackChunkName: "registration-chunk" */ '@/views/Registration.vue');
const Thankyou = () => import(/* webpackChunkName: "Thankyou-chunk" */ '@/views/Thankyou.vue');
const Annullo = () => import(/* webpackChunkName: "Annullo-chunk" */ '@/views/Annullo.vue');
const ThankyouPayment = () => import(/* webpackChunkName: "ThankyouPayment-chunk" */ '@/views/ThankyouPayment.vue');
const ThankyouTeam = () => import(/* webpackChunkName: "ThankyouTeam-chunk" */ '@/views/ThankyouTeam.vue');
const RootHome = () => import(/* webpackChunkName: "RootHome-chunk" */ '@/views/RootHome.vue');
const SearchResults = () => import(/* webpackChunkName: "SearchResults-chunk" */ '@/views/SearchResults.vue');
const CategoryView = () => import(/* webpackChunkName: "CategoryView-chunk" */ '@/views/CategoryView.vue');
const KitView = () => import(/* webpackChunkName: "KitView-chunk" */ '@/views/KitView.vue');
const ShoppingCartNew = () => import(/* webpackChunkName: "ShoppingCartNew-chunk" */ '@/views/ShoppingCartNew.vue');
const ToPayment = () => import(/* webpackChunkName: "ToPayment-chunk" */ '@/views/ToPayment.vue');
const PageDetails = () => import(/* webpackChunkName: "PageDetails-chunk" */ '@/views/PageDetails.vue');
const DettaglioProdotto = () => import(/* webpackChunkName: "DettaglioProdotto-chunk" */ '@/views/DettaglioProdotto.vue');
const ProfileDetails = () => import(/* webpackChunkName: "ProfileDetails-chunk" */ '@/views/ProfileDetails.vue');
const DatiUtente = () => import(/* webpackChunkName: "DatiUtente-chunk" */ '@/components/profile/DatiUtente.vue');
const IndirizziSpedizione = () => import(/* webpackChunkName: "IndirizziSpedizione-chunk" */ '@/components/profile/IndirizziSpedizione.vue');
const DatiFatturazione = () => import(/* webpackChunkName: "DatiFatturazione-chunk" */ '@/components/profile/DatiFatturazione.vue');
const CambiaPassword = () => import(/* webpackChunkName: "CambiaPassword-chunk" */ '@/components/profile/CambiaPassword.vue');
const ModificaDatiAnagrafici = () => import(/* webpackChunkName: "ModificaDatiAnagrafici-chunk" */ '@/components/profile/ModificaDatiAnagrafici.vue');
const RecuperoPasswordB2B = () => import(/* webpackChunkName: "RecuperoPasswordB2B-chunk" */ '@/views/RecuperoPasswordB2B.vue');
const RecuperoPasswordB2C = () => import(/* webpackChunkName: "RecuperoPasswordB2C-chunk" */ '@/views/RecuperoPasswordB2C.vue');
const PannTesserati = () => import(/* webpackChunkName: "PannTesserati-chunk" */ '@/components/profile/PannTesserati.vue');
const PannOrdini = () => import(/* webpackChunkName: "PannOrdini-chunk" */ '@/components/profile/StoricoOrdini.vue');
const Home = () => import(/* webpackChunkName: "Home-chunk" */ '@/views/Home.vue');
const SchedaOrdine = () => import(/* webpackChunkName: "SchedaOrdine-chunk" */ '@/views/SchedaOrdine.vue');
const GestoreImmagini = () => import(/* webpackChunkName: "GestoreImmagini-chunk" */ '@/views/GestoreImmagini.vue');
const GestoreTagliePrezzi = () => import(/* webpackChunkName: "GestoreTagliePrezzi-chunk" */ '@/views/GestoreTagliePrezzi.vue');
const ZoomFile = () => import(/* webpackChunkName: "ZoomFile-chunk" */ '@/views/ZoomFile.vue');

import store from '@/store'

const routes = [
{
    path: '/',
    name: 'Login',
    component: Login
},
{
    path: '/recupera-passwordb2b',
    name: 'RecuperaPasswordB2b',
    component: RecuperoPasswordB2B
},
{
    path: '/recupera-passwordb2c',
    name: 'RecuperaPasswordB2c',
    component: RecuperoPasswordB2C
},
{
    path: '/registrati',
    name: 'Registrazione',
    component: Registration
},
{
    path: '/registrati/grazie',
    name: 'RegistrazioneGrazie',
    component: Thankyou
},
{
    path: '/ordine/ok',
    name: 'OrdineOK',
    component: ThankyouPayment
},
{
    path: '/ordine/ko',
    name: 'OrdineKO',
    component: Annullo
},
{
    path: '/ordine/squadra',
    name: 'OrdineSquadra',
    component: ThankyouTeam
},
{
    path: '/Impostazioni/gestoreImmagini',
    name: 'GestoreImmagini',
    component: GestoreImmagini
},
{
    path: '/Impostazioni/gestoreTagliePrezzi',
    name: 'GestoreTagliePrezzi',
    component: GestoreTagliePrezzi
},
{
    path: "/pagine/:permalink",
    name: "Pages",
    component: PageDetails,
},
{
    path: '/home',
    name: 'RootHome',
    component: RootHome,
    beforeEnter(to, from, next){
    if(store.getters.getAuthenticated){
        next();
    }
    else{
        next('/');
    }
    },
    children:[
        {
            path: '',
            name: 'Home',
            component: Home
        },
        {
            path: "ricerca",
            name: "SearchResults",
            component: SearchResults
        },
        {
            path: "/Abbigliamento/:Categoria?",
            name: "Abbigliamento",
            component: CategoryView,
        },
        {
            path: "/Kit",
            name: "Kit",
            component: KitView,
        },
        {
            path: "/Dettaglio/:ProductId&:CodiceSocieta",
            name: "DettaglioProdotto",
            component: DettaglioProdotto,
        },
        {
            path: "/SchedaOrdine/:IdOrdine",
            name: "SchedaOrdine",
            component: SchedaOrdine
        },
        {
            path:"/ZoomFile/:IdImg&:ProductId&:CodiceSocieta",
            name: "ZoomFile",
            component: ZoomFile
        },
        {
            path:"carrello",
            name: "ShoppingCart",
            component: ShoppingCartNew,
            meta: {
                breadCrumbs: [
                    {
                        to: '/',          
                        text: 'Home' 
                    },
                    {
                        to: '',
                        text: ''
                    }         
                ]
            }
        },
        {
            path:"pagamento",
            name: "Payment",
            component: ToPayment,
        },
        {
            path: "profilo",
            name: "Profile",
            component: ProfileDetails,
            redirect: { name: 'UserDetails' },
            meta: {
                breadCrumbs: [
                    {
                        to: '/',          
                        text: 'Home' 
                    },
                    {
                        to: '',
                        text: 'Profile'
                    }         
                ]
            },
            children: [
                {
                    path: 'dati-utente',
                    name: 'UserDetails',
                    component: DatiUtente
                },
                {
                    path: 'gestione-tesserati',
                    name: 'GestioneTesserati',
                    component: PannTesserati
                },
                {
                    path: 'storico-ordini',
                    name: 'StoricoOrdini',
                    component: PannOrdini
                },
                {
                    path: 'indirizzo-spedizione',
                    name: 'DestinationAddresses',
                    component: IndirizziSpedizione
                },
                {
                    path: 'indirizzo-fatturazione',
                    name: 'BillingAddresses',
                    component: DatiFatturazione
                },
                {
                    path: 'cambia-password',
                    name: 'ChangePassword',
                    component: CambiaPassword
                },
                {
                    path: 'modifica-dati-personali',
                    name: 'ChangePersonalData',
                    component: ModificaDatiAnagrafici
                }
            ]
        }
    ]
}
]

const router = createRouter({
history: createWebHashHistory(process.env.BASE_URL),
routes,
scrollBehavior (to, from, savedPosition) {
    return { left: 0, top: 0 }
}
})

export default router
    