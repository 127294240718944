import moment from "moment";
import CarrelloRiga from "@/models/CarrelloRiga.model.js";
import Carrello from "@/models/Carrello.model.js";
import CartService from '@/services/cart.service.js';
import ApiResponse from '@/models/ApiResponse.model.js';

const state = {
    Carrello: null,
    NumeroArticoli: null,
}

const getters =  {
    getPrezzoTotale: (state) => {
        let carrello = getters.getCarrello(state); 
        let prezzoTotale = 0; 
        if(carrello && carrello.Testata){
            prezzoTotale = carrello.Testata.PrezzoTotale;
        }
        return prezzoTotale;
    }, 
    getCarrello: (state) => {     
        let carrello = {};
        if(state.Carrello){
            carrello = state.Carrello;
        }
        if(!carrello && localStorage.getItem("Carrello")){
            carrello = JSON.parse(localStorage.getItem("Carrello"));
        }
        return carrello;
    },
    getIdCarrello: (state) => {
        let Carrello = JSON.parse(localStorage.getItem("Carrello"));
        if(Carrello && Carrello.Testata){
            return Carrello.Testata.IdCarrello;
        }
        else{
            return 0;
        }        
    },
    getNumeroElementi: (state) => {
        let numeroElementi = 0;
        let carrello = getters.getCarrello(state);
        if(carrello && carrello.Righe){ 
            carrello.Righe.forEach(riga => {
                numeroElementi += riga.Quantita;
            });
        }
        return numeroElementi;
    },
    getShippingAddress: (state) => {
        return state.ShippingAddress;
    }
}

const mutations = {   
    setShippingAddress: (state, codiceDestinazione) => {
        state.ShippingAddress = codiceDestinazione
    },
    updateCarrello: (state, carrello) => {       
        state.Carrello = carrello;
        localStorage.setItem('Carrello', JSON.stringify(state.Carrello));
    },
    removeCart: (state) => {
        state.Carrello = null;
        localStorage.removeItem("Carrello");
    },
    setCarrello: (state, carrello) => {
        state.Carrello = carrello;
        localStorage.setItem('Carrello', JSON.stringify(carrello));
    }
}

const actions = {
    setCarrello: ({commit}, requestData) => {
        const date = new Date();
        function convertDate() {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date()
            return [d.getFullYear(), pad(d.getMonth()+1),  pad(d.getDate())].join('-')
        }
        if ( requestData.v ) {
            var data = JSON.stringify({
                "testata": 
                {
                    "DataCreazione": convertDate(),
                    "Valuta": "EUR",
                },
                "righe": [
                    {
                        "CodiceArticolo": requestData.product.CodiceArticolo,
                        "Quantita": requestData.qty,
                        "DescrizioneArticolo": requestData.product.DescrizioneArticolo,
                        "CodiceArticoloVariante": requestData.product.CodiceArticolo + '.' + requestData.v,
                        "CodiceSocieta": requestData.CodiceSocieta
                    }
                ]
            });
        } else {
            var data = JSON.stringify({
                "testata": 
                {
                    "DataCreazione": convertDate(),
                    "Valuta": "EUR",
                },
                "righe": [
                    {
                        "CodiceArticolo": requestData.product.CodiceArticolo,
                        "Quantita": requestData.qty,
                        "DescrizioneArticolo": requestData.product.DescrizioneArticolo,
                        "CodiceArticoloVariante": requestData.product.CodiceArticolo,
                        "CodiceSocieta": requestData.CodiceSocieta
                    }
                ]
            });        
        }
        if (requestData.shop == "b2b" ) {
            var data = JSON.stringify({
                "testata": 
                    {
                        "DataCreazione": convertDate(),
                        "Valuta": "EUR",
                    },
                "righe": [
                    {
                        "CodiceArticolo": requestData.product.CodiceArticolo,
                        "Quantita": requestData.qty,
                        "DescrizioneArticolo": requestData.product.DescrizioneArticolo,
                        "CodiceArticoloVariante": requestData.product.CodiceArticolo + '.' + requestData.v
                    }]
                }
            );
        }
        return CartService.setCart(requestData.token, requestData.shop, data).then(
            (data) => {
                if(data){                    
                    let response = new Carrello();
                    Object.assign(response, data.Data);
                    if(data.StatusCode == 200){      
                        commit('updateCarrello', response);                  
                        return true;
                    }
                    else{
                        return false;
                    }                     
                }
            }
        )
        .catch(
            error => {return false}
        )
    },
    getCarrello: ({commit}, requestData) => {
        return CartService.getCart(requestData.token, requestData.shop).then(
            (data) => {
                if(data){                   
                    commit('setCarrello',data);
                    return true;
                }
            }
        )
        .catch(
            error => {return false}
        )
    },
    getPagamentiDigitali: ({commit}, requestData) => {
        return CartService.getPaymentForm(requestData.metodoPagamento, requestData.codShop, requestData.importo).then(
            (data) => {
                if(data){
                    return data;
                }
            }
        )
        .catch(
            error => {return error}
        )
    },
    updateCartRow: ({commit}, requestData) => {        
        return CartService.updateRow(requestData.token, requestData.shop, requestData.idCarrello, requestData.idProdotto, requestData.idVariante, requestData.quantita).then(
            (data) => {   
                if(data){
                    let response = new Carrello();
                    Object.assign(response, data.Data);                     
                    if(data.StatusCode == 200){
                        commit('updateCarrello', response);
                        return true;         
                    }
                    else{
                        return false;
                    }
                }                                   
            }          
        )
        .catch(
            error => {return false}
        );
    },
    deleteCartRow:  ({commit}, requestData) => {   
        return CartService.deleteRow(requestData.token, requestData.shop, requestData.idCarrello, requestData.idProdotto, requestData.idVariante).then(
            (data) => {   
                if(data){                  
                    let response = new ApiResponse();             
                    Object.assign(response, data.Data); 
                    if(data.StatusCode == 200){
                        commit('updateCarrello', response);
                        return true;         
                    }
                    else{
                        return false;
                    }                
                }
            }          
        )
        .catch(
            error => {console.log(error);return false}
        );
    }
}

export default{
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}