import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vfmPlugin from 'vue-final-modal'
import Popper from 'vue3-popper';
import Toaster from '@meforma/vue-toaster';
import imageZoom from 'vue-image-zoomer';
import 'vue-image-zoomer/dist/style.css';
import './registerServiceWorker'
// import VueGtag from "vue-gtag";


createApp(App)
.use(store)
.use(router)
.use(vfmPlugin)
.use(Popper)
.use(Toaster)
.use(imageZoom)
// .use(VueGtag, {
//     config: { 
//       id: "UA-212036163-1",
//     },
//   }, router)
.mount('#app');

