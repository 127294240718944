import axios from "axios";
import OrderResponse from "@/models/OrderResponse.model.js";

const API_URL = process.env.VUE_APP_API_URL;

class OrderService{

    async sendOrder(token, shop="b2b", orderData){
        return axios({
            method: 'post',
            url: API_URL + shop + '/Ordini/InsertOrdine?codLang=IT',
            data: orderData,
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return Object.assign(new OrderResponse(), data.data);
                }              
            }
        )
        .catch(error => {return error;});
    }
    async SendTrans(token, shop, Transaction){
        return axios({
            method: 'post',
            url: API_URL + shop + '/Ordini/InsertLogTransaction?idTrans='+Transaction.codTrans,
            data: Transaction,
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        })
        .catch(error => {return error;});
    }
    async LogOrder(token, shop, order){
        return axios({
            method: 'post',
            url: API_URL + shop + '/Ordini/InsertLogOrder',
            data: order,
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        })
        .catch(error => {return error;});
    }

    async PaymentIntent(token, shop, order){
        return axios({
            method: 'post',
            url: API_URL + shop + '/Ordini/PaymentIntent?codLang=IT',
            data: order,
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            }
        }).then((data) =>{
            return data;
        })
        .catch(error => {return error;});
    }
}

export default new OrderService();