import CategoriesService from "@/services/categories.service.js";

const state = {
    categorie: null
}

const getters =  {
    getCategorieShop: (state) => {
        return state.categorie;
    },
}

const mutations = {
    setCategorie: (state, categorie) => {
        state.categorie = categorie;
    }
}

const actions = {
    GetCategorieFromApi: ({commit},data) => {
        return CategoriesService.getCategories(data.token, data.shop).then(
            (data) =>{
                if(data){
                    commit('setCategorie', data.Data);
                }
            }
        );
    }
}

export default{
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}