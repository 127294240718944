import { Promise } from 'core-js';
import AuthService from '@/services/auth.service.js';


const state = {
    Utente: null,
    Azienda: null,
    Carrello: null,
}

const getters =  {
    getUsername: (state) => {
        return state.Utente.Username;
    },
    getAzienda: (state) => {
        return  state.Azienda  ?? JSON.parse(localStorage.getItem("Azienda"));
    },
    getAuthenticated(state){
        if(state.Utente){
            return state.Utente;
        }
        else if (localStorage.getItem("Utente")){
            return JSON.parse(localStorage.getItem("Utente"));
        }
        else{
            return "";
        }       
    },
    getToken(state){
        if(state.Utente && state.Utente.Token){
            return state.Utente.Token;
        }
        else if(localStorage.getItem("Utente")){
            return JSON.parse(localStorage.getItem("Utente")).Token;
        }
        else {
            return "";
        }
        //return (state.Utente && state.Utente.Token) ? state.Utente.Token : JSON.parse(localStorage.getItem("Utente")).Token;
    },
    getShop(state){
        if(state.Utente && state.Utente.Shop){
            return state.Utente.Shop;
        }
        else if(localStorage.getItem("Utente")){
            return JSON.parse(localStorage.getItem("Utente")).Shop;
        }
        else {
            return "";
        }
        //return (state.Utente && state.Utente.Token) ? state.Utente.Token : JSON.parse(localStorage.getItem("Utente")).Token;
    },
    getLingua(state){    
        return state.Utente ? state.Utente?.Lingua : JSON.parse(localStorage.getItem("Utente")).Lingua;
    },
    getNome(state){       
        return state.Utente ? state.Utente?.Nome : JSON.parse(localStorage.getItem("Utente")).Nome;
    },
    getCognome(state){       
        return state.Utente ? state.Utente?.Cognome : JSON.parse(localStorage.getItem("Utente")).Cognome;
    },
    getAnagraficaUtente(state){       
        return JSON.parse(localStorage.getItem("Utente"));
    },
    getAnagraficaAzienda(state){       
        return JSON.parse(localStorage.getItem("Azienda"));
    },   
    getDestinazioni(state){
        return state.Azienda;
    },
    getAnagrafica(state){
        if(state.Utente && state.Utente?.Shop){
            return state.Utente?.Shop;
        }
        else if(localStorage.getItem("Utente")){
            return JSON.parse(localStorage.getItem("Utente")).Shop;
        }
        else {
            return "";
        }
    },
}

const mutations = {
    setUtente: (state, Utente) =>{
        state.Utente = Utente;   
        localStorage.setItem('Utente', JSON.stringify(Utente)); 
    },
    setAzienda: (state, azienda) => {
        state.azienda = azienda
        localStorage.setItem('Azienda', JSON.stringify(azienda));
    },
    setToken: (state, token) => {
        state.token = token
    },
    deleteUtente: (state) => {
        state.Utente = null;
    },
    deleteAzienda: (state) => {
        state.azienda = null;
    },
    deleteCarrello: (state) => {
        state.carrello = null;
    },
}

const actions = {
    login: ({commit}, user) => {
        let Member = AuthService.login(user.username, user.password, user.shop).then(
        (authData) => {             
                commit('updateCarrello', authData.Carrello);
                return Promise.resolve(authData);
            },
            error => {                      
                return Promise.reject(error);
            }
        );
        return Member
    }
}

export default{
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}