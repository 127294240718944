import ResourcesService from '@/services/resources.service.js'
import WebApiService from "@/services/webapi.service.js";

const state = {
    resources : null,   
    categorie: null,
    societa: null,
    province: null
}

const getters =  {
    getResources: (state) => (lang) => {
        return  state.resources[lang];
    },
    getCategorie: (state) =>{
        return state.categorie;
    },
    getSocieta: (state) =>{
        return state.societa;
    },
    getProvince: (state) => {
        return state.province;
    }
}

const mutations = {
    setResources: (state, resources)  => {
        state.resources = resources;
    },
    setCategorie: (state, categorie) =>{
        state.categorie = categorie;
    },
    setSocieta: (state, societa) =>{
        state.societa = societa;
    },
    setProvince: (state, province) =>{
        state.province = province;
    }
}

const actions = {
    getResourcesFromApi: ({commit}, data) => {       
        return ResourcesService.getRisorse(data.token).then(
            (data) => {   
                if(data){
                    if(data.Status == 401){
                        return false;
                    }
                    commit('setResources', data);
                    return true;
                }else{
                    return false;
                }                            
            }          
        ).catch(
            error => {return false}
        );
    },
    getCategorieCalcisticheFromApi: ({commit}, data) =>{
        return WebApiService.getNoAuth("b2c/Tesserato/GetCategorieCalcistiche").then(
            (data) => {
                if(data){
                    commit("setCategorie",data.Data);
                    return true;
                }else{
                    return false
                }
            }
        ).catch(
            error => {return false}
        );
    },
    getSocietaCalcistiche: ({commit}, data) => {
        return WebApiService.getNoAuth("b2c/DataSources/GetAnagrafiche").then(
            (data) => {
                if(data){
                    commit("setSocieta",data.Data);
                    return true;
                }else{
                    return false
                }
            }
        ).catch(
            error => {return false}
        );
    },
    getProvinceFromApi: ({commit}) =>{
        return WebApiService.getNoAuth("b2c/DataSources/GetProvince").then(
            (data) =>{
                if(data){
                    commit("setProvince", data.Data);
                    return true;
                }else{
                    return false;
                }
            }
        );
    }
}

export default{
    namespaced: false,
    state,
    getters,
    mutations,
    actions
}