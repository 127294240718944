import CarrelloTestata from "./CarrelloTestata.model";

export default class Carrello{

    Testata;
    Righe;
    
    constructor(){
        this.Testata = new CarrelloTestata();
        this.Righe = []; 
    }
}