import { createStore } from 'vuex';
import userauth from './modules/userauth.module';
import cart from './modules/cart.module';
import resources from './modules/resources.module';
import app from './modules/app.module';
import order from './modules/order.module';
import VuexPersist from 'vuex-persist';
import categorie from './modules/categorie.module.js';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage, // or window.sessionStorage or localForage
});


export default createStore({
  modules: {
    userauth,
    cart,
    resources,
    app,
    order,
    categorie
  },
  plugins: [vuexLocalStorage.plugin]

})
