<template>
<Loader/>
  <router-view/>   
</template>

<script>

import Loader from '@/components/Loader.vue';

export default {
  components:{
    Loader
  }

}
</script>

