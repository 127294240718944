import OrderService from '@/services/order.service.js';

const state = {
    Ordine: null,
    Testata: null,
    Righe: null,
    Spedizione:null,
    DatiSpedizione:null,
    Fatturazione:null,
    DatiFatturazione: null,
    CostoSpedizione:0,
    TotaleCarrelloCent: null,
    TotaleCarrello: null,
    NoteOrdine: null,
    K2AnagenIndir: 0
}

const getters =  {
    getK2AnagenIndir: (state) => {
        return state.K2AnagenIndir;
    },
    getTestata: (state) => {
        return state.Testata;
    },
    getTotaleCarrelloCent: (state) => {
        return state.TotaleCarrelloCent;
    },
    getTotaleCarrello: (state) => {
        return state.TotaleCarrello;
    },
    getDatiSpedizione: (state) => {
        if(state.DatiSpedizione){
            return state.DatiSpedizione;
        }
        else if(localStorage.getItem("Indirizzo-ssport")){
            return JSON.parse(localStorage.getItem("Indirizzo-ssport")).Token;
        }
        else {
            return "";
        }
    },
    getDatiFatturazione: (state) => {
        return state.DatiFatturazione;
    },
    getNoteOrdine: (state) => {
        return state.NoteOrdine;
    },
    getNoteOrdine: (state) => {
        return state.NoteOrdine;
    },
    getSpedizione: (state) => {
        return state.Spedizione;
    },
    getFatturazione: (state) => {
        return state.Fatturazione;
    },
    getCostoSpedizione: (state) => {
        return state.CostoSpedizione;
    }
}

const mutations = {
    setK2AnagenIndir(state, payload){
        state.K2AnagenIndir = payload;
    },
    setTestataOrdine: (state, payload) => {
        state.Testata = payload;
    },
    setRigheOrdine: (state, payload) => {
        state.Righe = payload;
    },
    setSpedizione: (state, payload) => {
        state.Spedizione = payload;
    },
    setDatiSpedizione: (state, payload) => {
        state.DatiSpedizione = payload;
        localStorage.setItem('Indirizzo-ssport', JSON.stringify(state.DatiSpedizione));
    },
    setFatturazione: (state, payload) => {
        state.Fatturazione = payload;
    },
    setDatiFatturazione: (state, payload) => {
        state.DatiFatturazione = payload;
    },
    setCostoSpedizione: (state, payload) => {
        state.CostoSpedizione = payload;
    },
    setTotaleCarrelloCent: (state, payload) => {
        state.TotaleCarrelloCent = payload;
    },
    setTotaleCarrello: (state, payload) => {
        state.TotaleCarrello = payload;
    },
    setNoteOrdine: (state, payload) => {       
        state.NoteOrdine = payload;
    }
}

const actions = {
    inserimentoOrdine: ({commit}, requestData) => {
        return OrderService.sendOrder(requestData.token, requestData.shop).then(
            (data) => {
                if(data){
                    let response = new AddCartResponse();
                    Object.assign(response, data);
                    localStorage.setItem('Carrello', JSON.stringify(data));
                    return data;
                }
            }
        )
        .catch(
            error => {return false}
        )
    },

    setSpedizione: ({commit}, payload) => {
        commit('setSpedizione', payload);
    },
    setDatiSpedizione: ({commit}, payload) => {
        commit('setDatiSpedizione', payload);
    },
    setTestataOrdine: ({commit}, payload) => {
        commit('setTestataOrdine', payload);
    },
    setRigheOrdine: ({commit}, payload) => {
        commit('setRigheOrdine', payload);
    },
    setFatturazione: ({commit}, payload) => {
        commit('setFatturazione', payload);
    },
    setDatiFatturazione: ({commit}, payload) => {
        commit('setDatiFatturazione', payload);
    },
    setCostoSpedizione: ({commit}, payload) => {
        commit('setCostoSpedizione', payload);
    },
    setTotaleCarrelloCent: ({commit}, payload) => {
        commit('setTotaleCarrelloCent', payload);
    },
    setTotaleCarrello: ({commit}, payload) => {
        commit('setTotaleCarrello', payload);
    },
    setOrdine: ({commit}, payload) => {
        commit('setOrdine', payload);
    },
    setNoteOrdine: ({commit}, payload) => {      
        commit('setNoteOrdine', payload);
    }
}

export default{
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
